export const propPortfolio = [
    {
      id: 1,
      title: "Property 1",
      img:
        "https://www.apple.com/v/app-store/b/images/overview/icon_appstore__ev0z770zyxoy_large_2x.png",
    },
    {
      id: 2,
      title: "Property 2",
      img:
        "https://www.apple.com/v/app-store/b/images/overview/icon_appstore__ev0z770zyxoy_large_2x.png",
    },
    {
      id: 3,
      title: "Property 3",
      img:
        "https://www.apple.com/v/app-store/b/images/overview/icon_appstore__ev0z770zyxoy_large_2x.png",
    },
  ];
  
  export const webPortfolio = [
    {
      id: 1,
      title: "Website Description 1",
      img: "https://www.apple.com/v/app-store/b/images/overview/icon_appstore__ev0z770zyxoy_large_2x.png",
    },
    {
      id: 2,
      title: "Website Description 2",
      img: "https://www.apple.com/v/app-store/b/images/overview/icon_appstore__ev0z770zyxoy_large_2x.png",
    },
    {
      id: 3,
      title: "Website  Description 3",
      img: "https://www.apple.com/v/app-store/b/images/overview/icon_appstore__ev0z770zyxoy_large_2x.png",
    },
  ];
  
  export const incompletePortfolio = [
    {
      id: 1,
      title: "TBA",
      img: "https://www.apple.com/v/app-store/b/images/overview/icon_appstore__ev0z770zyxoy_large_2x.png",
    },
    {
      id: 2,
      title: "TBA",
      img:
        "https://www.apple.com/v/app-store/b/images/overview/icon_appstore__ev0z770zyxoy_large_2x.png",
    },
    {
      id: 3,
      title: "TBA",
      img:
        "https://www.apple.com/v/app-store/b/images/overview/icon_appstore__ev0z770zyxoy_large_2x.png",
    },
    
  ];
  export const incompletePortfolio2 = [
    {
      id: 1,
      title: "TBA",
      img: "https://www.apple.com/v/app-store/b/images/overview/icon_appstore__ev0z770zyxoy_large_2x.png",
    },
    {
      id: 2,
      title: "TBA",
      img:
        "https://www.apple.com/v/app-store/b/images/overview/icon_appstore__ev0z770zyxoy_large_2x.png",
    },
    {
      id: 3,
      title: "TBA",
      img:
        "https://www.apple.com/v/app-store/b/images/overview/icon_appstore__ev0z770zyxoy_large_2x.png",
    },
    
  ];
  export const incompletePortfolio3 = [
    {
      id: 1,
      title: "TBA",
      img: "https://www.apple.com/v/app-store/b/images/overview/icon_appstore__ev0z770zyxoy_large_2x.png",
    },
    {
      id: 2,
      title: "TBA",
      img:
        "https://www.apple.com/v/app-store/b/images/overview/icon_appstore__ev0z770zyxoy_large_2x.png",
    },
    {
      id: 3,
      title: "TBA",
      img:
        "https://www.apple.com/v/app-store/b/images/overview/icon_appstore__ev0z770zyxoy_large_2x.png",
    },
    
  ];
  
  

  
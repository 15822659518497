import React from 'react'
import "./Works.scss"

export default function Works() {

    return (

        <div className={"works"} id={"works"}>
            <div className="left">
                <h2>Who am I?</h2>
                <h3>
                    Hi, my name is Michael Fauht! I'm a 4th-year senior student at MacEwan University
                    in Edmonton working on completing my BSc in Computer Science. Technology of any sort 
                    has been a passion of mine since I was young and inspired me to pursue my degree so I
                    can work in an industry I'm excited about and love to do. I'm comfortable writing code
                    in Java, SQL, Python, C#, and implementing Objected-Oriented design patterns. I'm also 
                    furthering my web development skills with JavaScript/React, CSS/SASS, and HTML. I enjoy
                    working on teams and I've experienced the software development lifecycle first hand during
                    projects that have exposed me to the agile development framework. When I'm not working on 
                    software development you can usually find me outdoors whether it be skiing, rock climbing, or camping.      
                </h3>
            </div>

            <div className="right">
                <div className="imgContainer">
                <img src="assets/computer3.png" alt="" />
                </div>
                
            </div>
        </div>

    )


}
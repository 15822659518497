import React from 'react'
import "./Topbar.scss"
import {Person, Mail} from "@material-ui/icons"

export default function Topbar({menuOpen, setMenuOpen}) {

    return (

        <div className={"topbar " + (menuOpen && "active")}>
            <div className={"wrapper"}>
                <div className={"left"}>
                    <a href={"#intro"} className={"logo"}>
                        Michael Fauht.
                    </a>
                    <div className={"itemContainer"}>
                        <Person className={"icon"}/>
                        <span>780-862-0123</span>
                    </div>
                    <div className={"itemContainer"}>
                        <Mail className={"icon"}/>
                        <span>michaelfauht@yahoo.ca</span>
                    </div>
                </div>
                <div className={"right"}>
                   <div className={"hamburger"} onClick = {()=>setMenuOpen(!menuOpen)}>
                       <span className={"line1"}></span>
                       <span className={"line2"}></span>
                       <span className={"line3"}></span>
                   </div>
                </div>
            </div>


        </div>

    )


}
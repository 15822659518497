import React, {useEffect, useState} from 'react'
import "./Portfolio.scss"
import PortfolioList from "../portfolio-list/PortfolioList";
import {
propPortfolio,
webPortfolio,
incompletePortfolio,
incompletePortfolio2,
incompletePortfolio3,
} from "../../data";



export default function Portfolio() {

    const [selected, setSelected] = useState("featured");
    const [data, setData] = useState([]);

    const list = [
        {
            id:"yegprop",
            title: "YEG Property Data",

        },
        {
            id:"portfolioweb",
            title: "Portfolio Website",

        },
        {
            id:"incompleteproj",
            title: "Project 3",

        },
        {
            id:"incompleteproj2",
            title: "Project 4",

        },
        {
            id:"incompleteproj3",
            title: "Project 5",

        },
    ];

    useEffect(()=> {

        switch(selected){

            case "yegprop":
                setData(propPortfolio);
                break;
            case "portfolioweb":
                setData(webPortfolio);
                break;
            case "incompleteproj":
                setData(incompletePortfolio);
                break;
            case "incompleteproj2":
                setData(incompletePortfolio2);
                break;
            case "incompleteproj3":
                setData(incompletePortfolio3);
                break;
            default:
                setData(propPortfolio);

        }


    }, [selected])

    return (

        <div className={"portfolio"} id={"portfolio"}>
            <h1>Projects (W.I.P)</h1>
            <ul>
                {list.map(item=>(
                    <PortfolioList
                        title={item.title}
                        active={selected == item.id}
                        setSelected={setSelected}
                        id = {item.id}
                    />
                ))}
            </ul>
            <div className={"container"}>
                {data.map((d) =>(

                
                <div className={"item"}>
                    <img src={d.img} alt={""}/>
                    <h3>{d.title}</h3>
                </div>
                ))}
            </div>
        </div>

    )


}
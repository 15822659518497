import React, {useEffect, useRef} from 'react'
import "./Intro.scss"
import {init} from 'ityped'



export default function Intro() {

    const textRef = useRef();

    useEffect(() => {
        init(textRef.current, {
            showCursor: true,
            backDelay: 1500,
            backSpeed:60,
            strings: ["Developer", "Designer", "Engineer"],
        });
    }, []);

    return (

        <div className={"intro"} id={"intro"}>
            <div className={"left"}>
                <div className={"imgContainer"}>
                    <img src={"assets/portrait-removebg.png"} alt = ""/>
                </div>
            </div>
            <div className={"right"}>
                <div className={"wrapper"}>
                     <h2>Hi there, I'm</h2>
                     <h1>Michael</h1>
                     <h3>
                         Aspiring Software <span ref={textRef}></span>
                     </h3>
                </div>
                <a href={"#portfolio"}>
                    <img src={"assets/down.png"}  alt={""}/>
                </a>
            </div>
        </div>

    )


}